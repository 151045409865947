import React, { useReducer } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Link } from "gatsby"
import Alert from "../Alert/Alert"
import Input from "../Input/Input"
import Button from "../Button/Button"
import Checkbox from "../Checkbox/Checkbox"
import Textarea from "../Textarea/Textarea"

const Form = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [token, setToken] = React.useState("")

  const INITIAL_STATE = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    body: "",
    status: "IDLE",
  }

  // Now we use a Reducer function to treat the state of all input elements as a whole state as a single value
  // Reducer take 2 arguments:
  // - the current state
  // - an action that is defined by us

  const reducer = (state, action) => {
    switch (action.type) {
      case "updateFieldValue":
        return { ...state, [action.field]: action.value }

      case "updateStatus":
        return { ...state, status: action.status }

      default:
        return INITIAL_STATE
    }
  }

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const setStatus = status => dispatch({ type: "updateStatus", status })

  const updateFieldValue = field => event => {
    dispatch({
      type: "updateFieldValue",
      field,
      value: event.target.value,
    })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setStatus("PENDING")

    if (!executeRecaptcha) {
      return
    }

    const result = await executeRecaptcha("contacto")

    setToken(result)

    fetch("/api/mail.php", {
      method: "POST",
      body: JSON.stringify(state),
    })
      .then(response => response.json())
      .then(response => {
        setStatus("SUCCESS")
      })
      .catch(error => {
        console.error(error)
        setStatus("ERROR")
      })
  }

  if (state.status === "SUCCESS") {
    return (
      <Alert
        alertType="success"
        alertTextStrong="¡Bien!"
        alertTextLight="Mensaje enviado con éxito"
      />
    )
  }

  return (
    <>
      {state.status === "ERROR" && (
        <div className="margin-bottom-xs">
          <Alert
            alertType="error"
            alertTextStrong="¡Oops!"
            alertTextLight="Ha habido un problema con el formulario"
          />
        </div>
      )}
      {state.status === "PENDING" && (
        <div className="margin-bottom-xs">
          <Alert
            alertType="info"
            alertTextStrong="En proceso"
            alertTextLight="Se está enviando el formulario"
          />
        </div>
      )}
      <form
        className="grid gap-sm"
        onSubmit={handleSubmit}
        data-netlify="true"
        method="post"
        name="ejemplo"
      >
        <input
          type="hidden"
          name="form-name"
          aria-hidden="true"
          value="ejemplo"
        />
        <div className="col-12">
          <Input
            type="text"
            label="Nombre *"
            id="name"
            name="name"
            required
            value={state.name}
            onChange={updateFieldValue("name")}
          />
        </div>
        <div className="col-12">
          <Input
            type="email"
            label="Correo electrónico *"
            id="email"
            name="email"
            required
            value={state.email}
            onChange={updateFieldValue("email")}
          />
        </div>
        <div className="col-12">
          <Input
            type="text"
            label="Teléfono de contacto"
            id="phone"
            name="phone"
            type="text"
            value={state.phone}
            onChange={updateFieldValue("phone")}
          />
        </div>
        <div className="col-12">
          <Input
            type="text"
            label="Asunto"
            id="subject"
            name="subject"
            type="text"
            value={state.subject}
            onChange={updateFieldValue("subject")}
          />
        </div>
        <div className="col-12">
          <Textarea
            label="Mensaje *"
            id="body"
            name="body"
            required
            value={state.body}
            onChange={updateFieldValue("body")}
            rows="5"
          />
        </div>
        <div className="col-12">
          <Checkbox
            id="legal"
            name="legal"
            required
            value={state.legal}
            onChange={updateFieldValue("legal")}
          >
            He leído y acepto la &nbsp;{" "}
            <Link to="/legales">Política de Privacidad</Link>
          </Checkbox>
        </div>
        <div className="col-12">
          <Button btnType="secondary" btnContent="Enviar" btnSize="sm" />
        </div>
      </form>
    </>
  )
}

export default Form
