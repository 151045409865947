import React from "react"

const Textarea = props => (
  <>
    <label htmlFor={props.id} className={`form-label ${props.classNameLabel}`}>
      {props.label}
    </label>
    <textarea
      placeholder={props.placeholder}
      className={`form-control width-100% ${props.classNameTextarea}`}
      id={props.id}
      name={props.id}
      disabled={props.isDisabled}
      aria-invalid={props.hasError}
      required={props.required}
      value={props.value}
      onChange={props.onChange}
      cols={props.cols}
      rows={props.rows}
    />
  </>
)

export default Textarea
