import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import Icon from "../components/Icon/Icon"
import Form from "../components/Form/Form"
import Layout from "../components/Layout"

const Contacto = () => {
  return (
    <Layout>
      <div className="container margin-y-lg">
        <section className="text-component">
          <div className="max-width-sm margin-x-auto">
            <h2 className="text-xxl font-heading font-bold">Contacto</h2>
            <p className="text-sm">
              Si desea reservar alojamiento con nosotros rellene el siguiente
              formulario y nos pondremos en contacto con ustedes. También puede
              ponerse en contacto con nosotros en los teléfonos:
            </p>
          </div>
        </section>
        <div className="max-width-sm margin-x-auto">
          <div className="grid gap-xl">
            <div className="col-12 col-6@sm">
              <section className="text-component">
                <span className="font-bold text-sm margin-bottom-xxxxxs">
                  Florentina
                </span>
                <div className="text-sm">
                  <p className="flex items-center">
                    <Icon
                      iconName="icon-phone"
                      iconClassName="margin-right-xs"
                    />
                    <a href="tel:+34926521131">926 521 131</a> &nbsp;/&nbsp;
                    <a href="tel:+34619267095">619 267 095</a>
                  </p>
                </div>
              </section>
            </div>
            <div className="col-12 col-6@sm">
              <section className="text-component">
                <span className="font-bold text-sm margin-bottom-xxxxxs">
                  Jesús
                </span>
                <div className="text-sm">
                  <p className="flex items-center">
                    <Icon
                      iconName="icon-phone"
                      iconClassName="margin-right-xs"
                    />
                    <a href="tel:+34926521131">926 521 131</a>
                  </p>
                </div>
              </section>
            </div>
          </div>
          <div className="margin-y-lg">
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
            >
              <Form />
            </GoogleReCaptchaProvider>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contacto
