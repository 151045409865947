import React from "react"
import { Link, withPrefix } from "gatsby"

import Icon from "../Icon/Icon"

class Info extends React.Component {
  componentDidMount() {
    let revealEffects = document.createElement("script")
    revealEffects.src = `${withPrefix("scripts/reveal-effects-script.js")}`
    revealEffects.id = "reveal-effects-script-js"
    document.body.appendChild(revealEffects)
  }

  componentWillUnmount() {
    document.getElementById("reveal-effects-script-js").remove()
  }

  render() {
    return (
      <div className="margin-top-xxl">
        <div className="box box--gray padding-y-xxl">
          <div className="container">
            <div className="grid gap-xxl">
              <div className="col-12 col-content@sm col-6@md col-4@lg">
                <section className="text-component reveal-fx reveal-fx--text-mask">
                  <h3 className="text-md font-bold color-black">
                    Dónde estamos
                  </h3>
                  <div className="text-sm">
                    <p className="flex items-center items-baseline@md">
                      <Icon
                        iconName="icon-location"
                        iconClassName="margin-right-xs"
                      />
                      <a
                        href="https://g.page/CasaRuralAlosnsoQuijano?share"
                        target="_blank"
                        rel="noreferrer"
                      >
                        C/ General Aguilera 58, Argamasilla de Alba
                      </a>
                    </p>
                  </div>
                </section>
              </div>
              <div className="col-12 col-content@sm col-6@md col-4@lg">
                <section
                  className="text-component reveal-fx reveal-fx--text-mask"
                  data-reveal-fx-delay="100"
                >
                  <h4 className="text-md font-bold color-black">
                    Nuestro email
                  </h4>
                  <div className="text-sm">
                    <p className="flex items-center">
                      <Icon
                        iconName="icon-mail"
                        iconClassName="margin-right-xs"
                      />
                      <a href="mailto:info@casaalonsoquijano.com">
                        info@casaalonsoquijano.com
                      </a>
                    </p>
                  </div>
                </section>
              </div>
              <div className="col-12 col-content@sm col-6@md col-4@lg">
                <section
                  className="text-component reveal-fx reveal-fx--text-mask"
                  data-reveal-fx-delay="200"
                >
                  <h5 className="text-md font-bold color-black">
                    Nuestros teléfonos
                  </h5>
                  <span className="font-bold text-sm margin-bottom-xxxxxs">
                    Florentina
                  </span>
                  <div className="text-sm">
                    <p className="flex items-center">
                      <Icon
                        iconName="icon-phone"
                        iconClassName="margin-right-xs"
                      />
                      <a href="tel:+34926521131">926 521 131</a>
                      &nbsp;/&nbsp; <a href="tel:+34619267095">619 267 095</a>
                    </p>
                  </div>
                  <span className="block font-bold text-sm margin-bottom-xxxxxs">
                    Jesús
                  </span>
                  <div className="text-sm">
                    <p className="flex items-center">
                      <Icon
                        iconName="icon-phone"
                        iconClassName="margin-right-xs"
                      />
                      <a href="tel:+34926521131">926 521 131</a>
                    </p>
                  </div>
                </section>
              </div>
              <div className="col-12">
                <div className="flex flex-center flex-column flex-row@sm text-sm margin-top-md">
                  <span className="margin-right-xs">
                    &copy; Casa Alonso Quijano
                  </span>
                  <Link to="/legales" className="margin-left-xs js-tab-focus">
                    Aviso legal
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Info
