import React from "react"

import Header from "./Header/Header"
import Info from "./Info/Info"

const Layout = ({ children }) => (
  <>
    <Header />
    <main>{children}</main>
    <Info />
  </>
)

export default Layout
