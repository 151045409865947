import React from "react"

const Checkbox = props => (
  <>
    <input
      type="checkbox"
      className="form-control checkbox"
      id={props.id}
      name={props.id}
      disabled={props.isDisabled}
      aria-invalid={props.hasError}
      required={props.required}
      value={props.value}
      onChange={props.onChange}
    />
    <label htmlFor={props.id}>{props.children}</label>
  </>
)

export default Checkbox
