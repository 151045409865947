import React from "react"

import Icon from "../Icon/Icon"

const Button = props => (
  <button
    className={`btn btn--${props.btnType} btn--${props.btnSize} ${props.btnClassName}`}
    aria-controls={props.btnAriaControlId}
  >
    {props.iconName && <Icon iconName={props.iconName} />}

    {props.btnContent && <span>{props.btnContent}</span>}
  </button>
)

export default Button
