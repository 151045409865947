import React from "react"
import { withPrefix, Link } from "gatsby"

class Header extends React.Component {
  componentDidMount() {
    let header = document.createElement("script")
    header.src = `${withPrefix("scripts/header-script.js")}`
    header.id = "header-script-js"
    document.body.appendChild(header)

    let animMenuBtn = document.createElement("script")
    animMenuBtn.src = `${withPrefix("scripts/anim-menu-btn-script.js")}`
    animMenuBtn.id = "anim-menu-btn-script-js"
    document.body.appendChild(animMenuBtn)

    let drawer = document.createElement("script")
    drawer.src = `${withPrefix("scripts/drawer-script.js")}`
    drawer.id = "drawer-script-js"
    document.body.appendChild(drawer)
  }

  componentWillUnmount() {
    document.getElementById("header-script-js").remove()
    document.getElementById("anim-menu-btn-script-js").remove()
    document.getElementById("drawer-script-js").remove()
  }

  render() {
    return (
      <header className="header-wrapper">
        <section className="header js-header">
          <div className="container">
            <div className="header__inner">
              <div className="header__logo">
                <h1 className="text-lg">
                  <Link to="/" className="text-decoration-none js-tab-focus">
                    Casa Rural Alonso Quijano
                  </Link>
                </h1>
              </div>
              <div
                className="drawer drawer--modal js-drawer js-drawer--modal margin-left-auto"
                id="header-list"
              >
                <div className="drawer__content">
                  <div className="drawer__body js-drawer__body">
                    <nav
                      className="header__nav js-header-nav js-sidenav"
                      role="navigation"
                      aria-label="Main"
                    >
                      <div className="header__label">
                        Casa Rural Alonso Quijano
                      </div>
                      <ul className="header__list">
                        <li className="header__item">
                          <Link
                            to="/la-casa"
                            className="header__link js-tab-focus"
                          >
                            La casa
                          </Link>
                        </li>
                        <li className="header__item">
                          <Link
                            to="/segunda-casa"
                            className="header__link js-tab-focus"
                          >
                            Segunda casa
                          </Link>
                        </li>
                        <li className="header__item">
                          <Link
                            to="/lugares"
                            className="header__link js-tab-focus"
                          >
                            Lugares
                          </Link>
                        </li>
                        <li className="header__item">
                          <Link
                            to="/como-llegar"
                            className="header__link js-tab-focus"
                          >
                            ¿Cómo llegar?
                          </Link>
                        </li>
                        <li className="header__item">
                          <Link
                            to="/contacto"
                            className="header__link js-tab-focus"
                          >
                            Contacto
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="header__actions">
                <button
                  className="btn btn--icon header__btn header__toggle anim-menu-btn hide@lg height-100% js-header-toggle-btn js-anim-menu-btn js-tab-focus padding-sm"
                  aria-label="Toggle menu"
                  aria-expanded="false"
                  aria-controls="header-list"
                >
                  <i
                    className="icon anim-menu-btn__icon anim-menu-btn__icon--close"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </section>
      </header>
    )
  }
}

export default Header
